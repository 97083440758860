import React from 'react';
import EStyleSheet from 'react-native-extended-stylesheet';
import { View, TouchableOpacity, Text } from 'react-native';
import { Ionicons, Feather } from '@expo/vector-icons';
import Modal from '../../services/modal';
import { useHistory } from '../../services/router';
import styles from './styles';
import Paragraph from '../../components/Content/Paragraph';
import { t } from '../../services/i18n';
import Title from '../Content/Title';

function ResponseModal({ isVisible, onHide, title, description, type, onConfirm, onSuccess }) {
	const history = useHistory();
	return (
		<Modal
			backdropColor={EStyleSheet.value('$white')}
			backdropOpacity={0.75}
			isVisible={isVisible}
			// onBackdropPress={onHide}
			// onBackButtonPress={onHide}
			animationIn="zoomIn"
			animationOut="zoomOut"
			// onSwipeComplete={onHide}
			useNativeDriver
			swipeDirection={['down', 'left', 'up', 'right']}
		>
			<View style={styles.modalContainer}>
				<View style={[styles.bubble, styles.bubbleReplace, { alignSelf: "center", marginTop: -60 }]}>
					<Ionicons style={styles.icon} name={type == "notification"?"notifications":"information"} />
				</View>
				<Title center title={title} />
				<Paragraph center>{description}</Paragraph>
				<View style={styles.content}>
					{type == "success" && (
						<TouchableOpacity onPress={() => { history.goBack(); onSuccess(); }} style={[styles.btn, styles.roundControl]}>
							<Text style={styles.btnText}>{t('Ok')}</Text>
						</TouchableOpacity>
					)}
					{type == "ask" && (
						<>
							<TouchableOpacity onPress={onConfirm} style={[styles.btn, styles.roundControl]}>
								<Text style={styles.btnText}>{t('Continue and Submit')}</Text>
							</TouchableOpacity>
							<TouchableOpacity onPress={onHide} style={[styles.btn, styles.roundControl]}>
								<Text style={styles.btnText}>{t('Cancel')}</Text>
							</TouchableOpacity>
						</>
					)}
					{type == "info" && (
						<>
							<TouchableOpacity onPress={onConfirm ? onConfirm : history.goBack} style={[styles.btn, styles.roundControl]}>
								<Text style={styles.btnText}>{t(title === t('nfcErrorEnableTitle') ? 'Activated' : 'Save and Exit')}</Text>
							</TouchableOpacity>
							<TouchableOpacity onPress={onHide} style={[styles.btn, styles.roundControl]}>
								<Text style={styles.btnText}>{t('Cancel')}</Text>
							</TouchableOpacity>
						</>
					)}

					{type == "notification" && (
						<>
							<TouchableOpacity onPress={onHide} style={[styles.btn, styles.roundControl]}>
								<Text style={styles.btnText}>{t('Done')}</Text>
							</TouchableOpacity>
						</>
					)}
				</View>
			</View>
		</Modal>
	);
}

export default ResponseModal;
