import React, { useEffect, useState } from 'react';
import {
	Text, View, TouchableHighlight,
	TouchableOpacity,
} from 'react-native';
import { useHistory, useLocation } from '../../services/router';
import styles from './styles';
import ENUM_TYPE_BUTTON from '../../constants/enumTypeButton';
import { AntDesign, Ionicons } from '@expo/vector-icons';
import { use } from 'i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';

function PinButton({
	children, content, onPress, locationId, buttonId, type
}) {
	const history = useHistory();
	const location = useLocation();
	// const [isPin, setIsPin] = useState(false);
	// useEffect(async () => {
	// 	const getPin = async () => {
	// 		const getPinButtons = await AsyncStorage.getItem('pinButtons');
	// 		const pinButtons = getPinButtons ? JSON.parse(getPinButtons) : [];
	// 		if (pinButtons && pinButtons.includes(buttonId)) {
	// 			setIsPin(true);
	// 		}
	// 		else {
	// 			setIsPin(false);
	// 		}
	// 	};
	// 	getPin();
	// }, []);
	const to = () => {
		switch (Number(type)) {
			case ENUM_TYPE_BUTTON.DOCUMENT:
				return `/location/${locationId}/document/${buttonId}`;
			case ENUM_TYPE_BUTTON.CHECKLIST:
				return `/location/${locationId}/button/${buttonId}/checklist`;
			case ENUM_TYPE_BUTTON.FIELD:
				return `/location/${locationId}/button/${buttonId}/field`;
			default:
				return `/location/${locationId}/button/${buttonId}`;
		}
	};
	function controlled() {
		//console.log(content,"location.pathname",location.pathname);
		if (content == "Daily Shift Report") {
			history.push(`${location.pathname}/DailyShiftReportHistory/${buttonId}`);
		}
		else if (content == "Incident Reports" || content == "Incident Report") {
			history.push(`${location.pathname}/incidentReportHistory/${buttonId}`);
		}
		else if (content == "Analytics" || content.toLowerCase().includes('analytic')) {
			history.push(`${location.pathname}/analytics`);
		}
		else {
			if (onPress) {
				onPress();
			}
			history.push(to());
		}
	}
	// const onPin = async () => {
	// 	const getPinButtons = await AsyncStorage.getItem('pinButtons');
	// 	const pinButtons = getPinButtons ? JSON.parse(getPinButtons) : [];
	// 	if (pinButtons && pinButtons.includes(buttonId)) {
	// 		setIsPin(false);
	// 	}
	// 	else {
	// 		setIsPin(true);
	// 	}
	// 	if (pinButtons && pinButtons.includes(buttonId)) {
	// 		const newPinButtons = pinButtons.filter(item => item !== buttonId);
	// 		await AsyncStorage.setItem('pinButtons', JSON.stringify(newPinButtons));
	// 	}
	// 	else {
	// 		const newPinButtons = [...pinButtons, buttonId];
	// 		await AsyncStorage.setItem('pinButtons', JSON.stringify(newPinButtons));
	// 	}

	// }
	return (
		// <TouchableHighlight onPress={controlled} style={styles.link}>
		// 	<View style={styles.shadow}>
		// 		<View style={styles.container}>
		// 			<Text style={styles.text}>{children || content}</Text>
		// 			{/* <AntDesign onPress={async () => { await onPin() }} style={styles.subTitleIcon} name={isPin ? 'pushpin' : 'pushpino'} /> */}
		// 		</View>
		// 	</View>
		// </TouchableHighlight>

		<TouchableOpacity onPress={controlled}>
			<View accessible style={styles.container}>
				<Ionicons style={styles.icon} name={"radio-button-on"} />
				<Text style={styles.text}>{children || content}</Text>
			</View>
		</TouchableOpacity>
	);
}

export default PinButton;
