import React, { useEffect, useState } from 'react';
import {
	Dimensions,
	Text, View,
} from 'react-native';
import { useHistory, useLocation } from '../../services/router';
import {
	PieChart,
	ProgressChart
} from "react-native-chart-kit";
import API from '../../config/api';
import request from '../../utils/request';

function PieChartComponent({
	children, content, onPress, locationId, type,data
}) {
	const history = useHistory();
	const location = useLocation();

	const chartConfig = {
		backgroundGradientFrom: "#05036E",
		backgroundGradientFromOpacity: 1,
		backgroundGradientTo: "#479DFF",
		backgroundGradientToOpacity: 0.5,
		color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
		strokeWidth: 2, // optional, default 3
		barPercentage: 0.5,
		useShadowColorFromDataset: false
	};

	
	return (
		<View style={{ marginBottom: 10, marginRight: 10 }}>
			<PieChart
				data={data}
				width={Dimensions.get("window").width - 35}
				height={220}
				chartConfig={chartConfig}
				accessor={"population"}
				backgroundColor={"transparent"}
				paddingLeft={"5"}
				center={[10, 0]}
				absolute
			/>
		</View>
	);
}

export default PieChartComponent;
