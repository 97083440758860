import _ from "lodash";
import moment from "moment";
import login from "../login";

const DROPDOWN_STR = "dropdown";
const QUESTION_STR = "question";
const TEXT_LWR_STR = "text";
const NFC_REASON_STR = "nfcReason";
const NFC_STR = "nfc";
const RESPONSE_STR = "response";
const PICTURE_STR = "picture";
const DATE_STR = "date";
const PIC_STR = "pic";
const NFC_VALIDATED = "NFCValidated";
const DEFICIENCY_STR = "deficiency";
const TRUE_STR = "true";
const FALSE_STR = "false";
const RESPONSE_TYPE_STR = "responseType";
const PICTURE_OPTION_STR = "pictureOption";
const NFC_VALIDATION = "NFCValidation";
const QUESTIONS_STR = "questions";


async function mapQuestions(requestBody, questionsJsonArray, request = null, v2 = true) {
    const map = new Map();

    const questionsElement = requestBody;
    if (questionsElement && typeof questionsElement === 'object' && !Array.isArray(questionsElement)) {
        const jQs = questionsElement;

        for (let i = 0; i < questionsJsonArray.length; i++) {
            const index = _.indexOf(questionsJsonArray, questionsJsonArray[i]);
            const mapObject = new Map();
            const question = questionsJsonArray[i];
            const type = question[RESPONSE_TYPE_STR];
            const pictureOption = question[PICTURE_OPTION_STR];
            const NFCValidation = question[NFC_VALIDATION];

            let responseValue = v2 ?
                (jQs[QUESTION_STR + index] === null || jQs[QUESTION_STR + index] === undefined ? null : jQs[QUESTION_STR + index]) :
                request[QUESTION_STR + index];

            if (type !== TEXT_LWR_STR && type !== DROPDOWN_STR) {
                responseValue = String(responseValue === TRUE_STR ? responseValue : null);
            }

            mapObject.set(RESPONSE_STR, responseValue);

            mapObject.set(DATE_STR, v2 ?
                (jQs[QUESTION_STR + index + DATE_STR] === null || jQs[QUESTION_STR + index + DATE_STR] === undefined ? null : jQs[QUESTION_STR + index + DATE_STR]) :
                request[QUESTION_STR + index + DATE_STR]);

            mapObject.set(NFC_REASON_STR, v2 ?
                (jQs[QUESTION_STR + index + NFC_REASON_STR] === null || jQs[QUESTION_STR + index + NFC_REASON_STR] === undefined ? null : jQs[QUESTION_STR + index + NFC_REASON_STR]) :
                request[QUESTION_STR + index + NFC_REASON_STR]);

            if (pictureOption) {
                mapObject.set(PICTURE_STR, v2 ?
                    (jQs[QUESTION_STR + index + PIC_STR] === null || jQs[QUESTION_STR + index + PIC_STR] === undefined ? null : jQs[QUESTION_STR + index + PIC_STR]) :
                    request[QUESTION_STR + index + PIC_STR]);
            }

            if (NFCValidation) {
                mapObject.set(NFC_VALIDATED, v2 ?
                    (jQs[QUESTION_STR + index + NFC_STR] === null || jQs[QUESTION_STR + index + NFC_STR] === undefined ? null : jQs[QUESTION_STR + index + NFC_STR]) :
                    request[QUESTION_STR + index + NFC_STR] === TRUE_STR);
            }

            if (v2 ? jQs[QUESTION_STR + index + DEFICIENCY_STR] !== null && jQs[QUESTION_STR + index + DEFICIENCY_STR] !== undefined :
                request[QUESTION_STR + index + DEFICIENCY_STR] !== null) {
                mapObject.set(DEFICIENCY_STR, v2 ?
                    (jQs[QUESTION_STR + index + DEFICIENCY_STR] === null || jQs[QUESTION_STR + index + DEFICIENCY_STR] === undefined ? null : jQs[QUESTION_STR + index + DEFICIENCY_STR]) :
                    request[QUESTION_STR + index + DEFICIENCY_STR]);
            }

            map.set(index, mapObject);
        }
        return map;
    } else {
        return null;
    }
}

export default async function convertQueCheckList(requestBody, questionsJsonArray) {
    const field5=questionsJsonArray?.field3;
    const field2=questionsJsonArray?.id;
    questionsJsonArray = JSON.parse(questionsJsonArray?.field8)?.questions;
    let questionsElement = questionsJsonArray;
    const questions = await mapQuestions(requestBody, questionsJsonArray);
    const hasDeficiencies = [false];
    let timestamp = 0;
    questionsJsonArray.forEach((q, index) => {
        const question = q; // Assuming q is already a plain object (JSON)
        const map = questions.get(index); // Assuming `questions` is a Map object

        // Safely access properties from the Map using .get()
        question[RESPONSE_STR] = map ? map.get(RESPONSE_STR) || null : null;
        question[DATE_STR] = map ? map.get(DATE_STR) || null : null;
        timestamp=map && map.get(DATE_STR);
        // Handle picture property
        if (map && map.get(PICTURE_STR) != null) {
            question[PICTURE_STR] = map.get(PICTURE_STR)?.uri;
        }

        // Handle NFC validation property
        if (map && map.get(NFC_VALIDATED) != null) {
            question[NFC_VALIDATED] = map.get(NFC_VALIDATED);
        }

        // Handle deficiency property and mark hasDeficiencies
        if (map && map.get(DEFICIENCY_STR) != null) {
            question[DEFICIENCY_STR] = map.get(DEFICIENCY_STR);
            hasDeficiencies[0] = TRUE; // Ensure hasDeficiencies is mutable
        }

        // Handle NFC reason property
        if (map && map.get(NFC_REASON_STR) != null) {
            question[NFC_REASON_STR] = map.get(NFC_REASON_STR);
        }

        // Replace the original question with the updated question
        questionsJsonArray[index] = question;
    }); // Your timestamp in milliseconds
    const date1 = moment(timestamp).format("YYYY-MM-DD HH:mm:ss.SSS Z"); 
    const date2 = moment(timestamp).format("YYYY-MM-DD HH:mm:ss.SSS Z"); 
    const user = await login.getUser();
    // Assuming questionsElement is an object, not a JsonObject
    questionsElement[QUESTIONS_STR] = questionsJsonArray;
    const response = {};
    // Assuming response is a plain JavaScript object
    response.siteId = 89;
    response.field1 = RESPONSE_STR;
    response.field2 = String(field2);  // Convert genDataId to string
    response.field3 = JSON.stringify({questions:questionsElement});  // Assuming questionsElement is a JavaScript object
    response.field4 = String(hasDeficiencies[0]);  // Convert to string (if necessary)
    response.field5 = field5;  // Assuming field3 exists in foxGenData
    response.field6 = String(user?.id);  // Convert person ID to string
    response.field7 = FALSE_STR;  // Assuming FALSE_STR is defined elsewhere
    response.dateField1 = date1;  // Assuming date is a valid value (Date object or string)
    response.dateField2 = date2;  // Same as above

    return response;


}