import React from 'react';
import { Text } from 'react-native';
import styles from './styles';

function Title({ title, children, center }) {
	return (
		<Text style={[styles.title, center && {textAlign:"center"}]}>{children || title}</Text>
	);
}

export default Title;
