import React, { memo, useEffect, createRef, useState } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import he from 'he';
import firestore from '@react-native-firebase/firestore';

import { ScrollView, Text } from 'react-native';
import { useInjectReducer } from '../../../utils/injectReducer'; // eslint-disable-line
import { useInjectSaga } from '../../../utils/injectSaga'; // eslint-disable-line

import reducer from './reducer';
import saga from './saga';
import makeSelectLoginContact from './selectors';
import { makeSelectUser } from '../../../providers/UserProvider/selectors';
import {
	changeInput, submitForm, unmount, reset,
} from './actions';

import Title from '../../../components/Content/Title';
import { t } from '../../../services/i18n';
import Input from '../../../components/Form/Input';
import Control from '../../../components/Control';
import TextArea from '../../../components/Form/TextArea';
import SubTitle from '../../../components/Content/SubTitle';
import styles from './styles';
import Alert from '../../../services/alert';
import ToggleBool from '../../../components/ToggleBool';
import PickerCustom from '../../../components/Form/Picker';
import { stylesInput } from '../../../components/Form/styles';

const key = 'logincontact';

function Container({ scroll, children }) {
	if (scroll) {
		return (
			<ScrollView contentContainerStyle={styles.scrollInner} bounces={false}>
				{children}
			</ScrollView>
		);
	}
	return <>{children}</>;
}

function LoginContact({
	loginContact,
	user,
	handleChangeInput,
	handleSubmitForm,
	history,
	handleUmount,
	handleReset,
	inApp = false,
}) {
	useInjectReducer({ key, reducer });
	useInjectSaga({ key, saga });
	const [toggly, setToggly] = useState(false);
	// const [supportType, setSupportType] = useState("Technical");

	useEffect(() => () => {
		// reset the error state on unmount
		handleUmount();
	}, []);

	useEffect(() => {
		if (user.email) {
			handleChangeInput(he.decode(user.email), 'email');
		}
		if (user.firstName && user.lastName) {
			handleChangeInput(`${he.decode(user.firstName)} ${he.decode(user.lastName)}`, 'name');
		}
	}, [user]);

	const {
		email, name, message, loading, error, sent, phone, question1, question2, question3, question4, question5, supportType
	} = loginContact;
	const secondInput = createRef();
	const thirdInput = createRef();
	const fourthInput = createRef();

	useEffect(() => {
		if (error.server) {
			Alert.alert(t('serverErrorTitle'), t('serverErrorMessage'));
		}
	}, [error.server]);

	useEffect(() => {
		if (sent) {
			Alert.alert(
				t('messageSubmittedTitle'),
				t('messageSubmittedMessage'),
				[
					{ text: 'Message Submitted', onPress: () => handleReset() },
				],
				{ cancelable: false },
			);
		}
	}, [sent]);

	return (
		<Container scroll={inApp}>
			{inApp ? <SubTitle title={t('sendUsAMessage')} /> : <Title title={t('sendUsAMessage')} />}
			<Input
				value={email}
				onChangeText={(value) => handleChangeInput(value, 'email')}
				placeholder={t('emailAddress')}
				autoCapitalize="none"
				autoCompleteType="email"
				keyboardType="email-address"
				error={error.email.empty || !error.email.valid}
				returnKeyType="next"
				onSubmitEditing={() => secondInput.current.focus()}
			/>
			<Input
				value={name}
				onChangeText={(value) => handleChangeInput(value, 'name')}
				placeholder={t('fullName')}
				error={error.name}
				returnKeyType="next"
				onSubmitEditing={() => thirdInput.current.focus()}
				ref={secondInput}
			/>
			<Input
				value={phone}
				onChangeText={(value) => handleChangeInput(value, 'phone')}
				placeholder={t('phone')}
				error={error.phone}
				returnKeyType="next"
				onSubmitEditing={() => fourthInput.current.focus()}
				ref={thirdInput}
			/>
			<Text
				style={[{
					zIndex: 2,
					color: '#7E879F',
					fontFamily: '$fontMedium',
					marginBottom:10
				}]}
			>
				Type
			</Text>
			<PickerCustom
				options={[{ key: "Technical", label: "Technical Support", value: "Technical" }, { key: "Subscription", label: "Subscription Support", value: "Subscription" }]}
				selected={supportType}
				onChange={(value) => { handleChangeInput(value, 'supportType') }}
			/>
			{supportType == "Subscription" ?
				<TextArea
					value={message}
					onChangeText={(value) => handleChangeInput(value, 'message')}
					placeholder={t('yourMessage')}
					error={error.message}
					ref={fourthInput}
					style={{ marginHorizontal: 2 }}
				/> :
				<>
					<Input
						value={question1}
						onChangeText={(value) => handleChangeInput(value, 'question1')}
						placeholder={t('Where were you in the building?')}
						error={error.question1}
					/>
					<Input
						value={question2}
						onChangeText={(value) => handleChangeInput(value, 'question2')}
						placeholder={t('What checklist were you completing?')}
						error={error.question2}
					/>
					<Input
						value={question3}
						onChangeText={(value) => handleChangeInput(value, 'question3')}
						placeholder={t('Last scan point you had scanned?')}
						error={error.question3}
					/>
					<Input
						value={question4}
						onChangeText={(value) => handleChangeInput(value, 'question4')}
						placeholder={t('What time did it happen?')}
						error={error.question4}
					/>
					<TextArea
						value={message}
						onChangeText={(value) => handleChangeInput(value, 'message')}
						placeholder={t('yourMessage')}
						error={error.message}
						ref={fourthInput}
						style={{ marginHorizontal: 2 }}
					/>
				</>}
			<Control
				onPress={() => {
					const todayDate = new Date().toLocaleString().replaceAll('/', '-').replace(' ', '').split(','); // Get the current date
					// firestore()
					// 	.collection(`Support/Report/${todayDate[0]}/${email}/${name}`)
					// 	.doc(todayDate[1]) // Specify a document ID (or let Firestore generate one)
					// 	.set({
					// 		res:{email, name, message, phone, supportType, question1, question2, question3, question4},
					// 		data: JSON.stringify({email, name, supportType, message, phone, question1, question2, question3, question4}),  // Assuming 'error' is an object you want to store
					// 		timestamp: new Date().toLocaleString(), // Optional: add a timestamp
					// 	});
					handleSubmitForm(email, name, message, phone, supportType, question1, question2, question3, question4)
				}}
				title={t('sendYourMessage')}
				loading={loading}
			/>
			{!inApp && (
				<Control
					onPress={() => history.push('/')}
					theme="alt"
					title={t('cancel')}
				/>
			)}
		</Container>
	);
}

const mapStateToProps = createStructuredSelector({
	loginContact: makeSelectLoginContact(),
	user: makeSelectUser(),
});

export function mapDispatchToProps(dispatch) {
	return {
		handleChangeInput: (value, name) => dispatch(changeInput(value, name)),
		handleSubmitForm: (email, name, message, phone, supportType, question1, question2, question3, question4) => dispatch(submitForm(email, name, message, phone, supportType, question1, question2, question3, question4)),
		handleUmount: () => dispatch(unmount()),
		handleReset: () => dispatch(reset()),
	};
}

const withConnect = connect(
	mapStateToProps,
	mapDispatchToProps,
);

export default compose(
	withConnect,
	memo,
	withRouter,
)(LoginContact);
