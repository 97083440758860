import React, { memo, useEffect, createRef, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import moment from "moment";
import {
  Alert,
  KeyboardAvoidingView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  Platform, ScrollView, Image, SafeAreaView
} from "react-native";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useParams, useLocation, useHistory } from "../../../services/router";
import ButtonHeader from "../../../components/ButtonHeader";
import Content from "../../../components/Content/Content";
import Button from "../../../components/Button";
import styles from "./styles";
import { makeSelectButtons } from "../../../providers/ButtonsProvider/selectors";
import { makeSelectLocations } from "../../../providers/LocationsProvider/selectors";
import SubTitle from "../../../components/Content/SubTitle";
import ModalTakePicture from "../../../components/ChecklistCard/ModalTakePicture";
import Separator from "../../../components/Separator";
import Control from "../../../components/Control";
import { t } from "../../../services/i18n";
import Paragraph from "../../../components/Content/Paragraph";
import API from "../../../config/api";
import request from "../../../utils/request";
import login from "../../../services/login";
import ModalRead from "../../../components/ChecklistCard/ModalRead";
import {
  getCurrentLocation,
  stopLocation,
} from "../../../providers/UserLocationProvider/actions";
import { makeSelectUserLocation } from "../../../providers/UserLocationProvider/selectors";
import DatePicker from "../../../components/Form/DatePicker";
import ToggleBool from "../../../components/ToggleBool";
import axios from "axios";
import DropDownPicker from "react-native-dropdown-picker";
import ImageSecured from "../../ImageSecured";
import Modal from "../../../services/modal";
import EStyleSheet from "react-native-extended-stylesheet";
import MultiPickerCustom from "../../../components/Form/MultiPicker";
function IncidentReport({ buttons, locations, userLocations }) {
  const dispatch = useDispatch();
  const { locationId, buttonId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const startTimeRef = createRef();
  const endTimeRef = createRef();
  const [dailyShiftReportData, setDailyShiftReportData] = useState([]);
  const [date, setDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [incidentInfo, setIncidentInfo] = useState(location.state?.incidentInfo);
  const [startTime, setStartTime] = useState("");
  const [dateOfIncident, setDateOfIncident] = useState(moment().format("YYYY-MM-DD"));
  const [timeOfIncident, setTimeOfIncident] = useState(moment().format("HH:mm a"));
  const [incidentDesc, setIncidentDesc] = useState("");
  const [incidentType, setIncidentType] = useState([]);
  const [occurrenceNumber, setOccurrenceNumber] = useState("");
  const [unitNo, setUnitNo] = useState("");
  const [involvedPersons, setInvoledPersons] = useState([
    {
      "firstName": "",
      "lastName": ""
    }
  ]);
  const [note, setNote] = useState("");
  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [licenseIdNo, setLicenseIdNo] = useState("");

  const [ambulanceName, setAmbulanceName] = useState("");
  const [ambulanceNumber, setAmbulanceNumber] = useState("");
  const [fireName, setFireName] = useState("");
  const [fireNumber, setFireNumber] = useState("");
  const [policeName, setPoliceName] = useState("");
  const [policeNumber, setPoliceNumber] = useState("");
  const [other1Name, setOther1Name] = useState("");
  const [other2Name, setOther2Name] = useState("");
  const [isEmergency, setIsEmergency] = useState(false);
  const [emergency, setEmergency] = useState([]);
  const [shiftStarted, setShiftStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTraceLogsLoading, setIsTraceLogsLoading] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [open, setOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [imageViewModal, setImageViewModal] = useState(null);
  const [images, setImages] = useState([]);
  const [types, setTypes] = useState([]);
  const currentLocation = locations.find(({ id }) => id === locationId);
  const currentButton = buttons.find((button) => button.id === buttonId);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let list = [];
        if (currentLocation.field29) {
          list = JSON.parse(currentLocation.field29);
        }
        setTypes(list);
  
        if (location?.state?.incidentInfo?.id) {
          await getIncidentInfo(location.state.incidentInfo.id);
        }
      } catch (error) {
        console.error('Error fetching incident info or processing data:', error);
      }
    };
  
    fetchData();
  }, [currentLocation.field29]);
  // }, [currentLocation.field29, location?.state?.incidentInfo?.id]);

  const getIncidentInfo = async (id) => {
    try {


      if (id) {
        const url = `${API()}/action/incidentReport?locationId=${locationId}&id=${id}`;

        const options = {
          url,
          method: "GET",
        };

        const { data } = await request(options);
        console.log(data, id, "asdasdas");
        if (data && data?.length) {
          let incidentInfo = data[0];
          setFirstName(incidentInfo.firstName);
          setLastName(incidentInfo.lastName);
          setIncidentDesc(incidentInfo.incidentDesc);
          setIncidentType(incidentInfo.incidentType ? incidentInfo.incidentType.split(",") : []);
          setInvoledPersons(JSON.parse(incidentInfo?.involvedPersons));
          setUnitNo(incidentInfo?.unitNo)
          setLicenseIdNo(incidentInfo?.licenseIdNo)
          setOccurrenceNumber(incidentInfo?.occurrenceNumber);
          setIsEmergency(incidentInfo.anyEmergency);
          setTimeOfIncident(moment(incidentInfo.timeOfIncident, "hh:mm").format("HH:mm a"));
          setDateOfIncident(incidentInfo.dateOfIncident);
          setImages(incidentInfo?.photos ? incidentInfo?.photos.split(",") : []);
  
          setEmergency(JSON.parse(incidentInfo?.emergency));
  
          setPoliceName(incidentInfo?.policeName);
          setPoliceNumber(incidentInfo?.policeNumber);
          setFireName(incidentInfo?.fireName);
          setFireNumber(incidentInfo?.fireNumber);
          setAmbulanceName(incidentInfo?.ambulanceName);
          setAmbulanceNumber(incidentInfo?.ambulanceNumber);
          setOther1Name(incidentInfo?.other1Name)
          setOther2Name(incidentInfo?.other2Name)
        }
      }
    } catch (error) {
      console.log(error, "error");
    }
  }



  function dataURItoBlob(dataURI) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString;
    if (dataURI.split(",")[0].indexOf("base64") >= 0) {
      byteString = atob(dataURI.split(",")[1]);
    } else {
      byteString = unescape(dataURI.split(",")[1]);
    }

    // separate out the mime component
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }


  const processImages = async () => {
    try {
      let mediaId = "";
      let mediaList = [];
      for (const eachPic of images) {
        if (eachPic && !incidentInfo?.photos.split(",").includes(eachPic)) {
          // try to upload Image
          const pic = eachPic;
          const form = new FormData();

          if (Platform.OS === "web") {
            const fileType = pic.uri.split(";")[0].split("/")[1];
            const file = dataURItoBlob(pic.uri);
            // append the pics in the form data
            form.append(
              "file",
              file,
              `${incidentInfo?.id}-${dailyShiftReportData?.length}.${fileType}`
            );
          } else {
            const uriParts = pic.uri.split(".");
            const fileType = uriParts[uriParts.length - 1];

            form.append("file", {
              uri: pic.uri,
              name: `${incidentInfo?.id}namess.${fileType}`,
              type: `image/${fileType}`,
            });
          }
          form.append("ajax", true);
          const url = `${API()}/action/uploadSecureFile`;

          const options = {
            url,
            method: "POST",
            data: form,
            headers: {
              "Content-Type": "multipart/form-data",
              // 'Authorization': token
            },
          };

          let fetchResponse = null;
          let imgData = null;
          if (Platform.OS === "web") {
            fetchResponse = await request(options);
            imgData = fetchResponse?.data?.media?.mediaIds;
          } else {
            const uploadImage = await fetch(url, {
              method: "POST",
              body: form,
              headers: options.headers,
            });
            fetchResponse = await uploadImage.json();
            imgData = fetchResponse?.media?.mediaIds;
          }
          const split = imgData?.length && imgData[0].split(" - ");
          mediaId = split?.length ? split[0] : null;
          mediaList.push(mediaId);
        }
        else {
          mediaList.push(eachPic);
        }
      }
      return mediaList;
    }
    catch (err) {
      //console.log(err, "rr");
    }
  }
  const validate = async () => {


    if (lastName == "" || firstName == "") {
      alert("Please fill full name.");
      return false;
    }

    const allNamesFilled = involvedPersons.every(item => item.firstName !== "" && item.lastName !== "");

    if (!allNamesFilled) {
      alert("Please fill all involved Persons names or delete empty record.");
      return false;
    }

    if (isEmergency) {
      if (emergency.includes("Police")) {
        if (policeName == "" || policeNumber == "") {

          alert("Please fill emergency police information.");
          return false;
        }
      }
      if (emergency.includes("Ambulance")) {
        if (ambulanceName == "" || ambulanceNumber == "") {

          alert("Please fill emergency ambulance information.");
          return false;
        }
      }
      if (emergency.includes("Fire")) {
        if (fireName == "" || fireNumber == "") {

          alert("Please fill emergency Fire information.");
          return false;
        }
      }
      if (emergency.includes("Other")) {
        if (other1Name == "") {

          alert("Please fill emergency other information.");
          return false;
        }
      }
    }


    return true;
  }
  const onAddReport = async () => {
    try {
      const check = await validate();
      if (check) {
        setBtnLoader(true);
        setIsLoading(true);
        let mediaList = await processImages();

        const url = `${API()}/action/incidentReport`;
        const payload = {
          locationId: Number(locationId),
          firstName: firstName,
          lastName: lastName,
          dateOfIncident: dateOfIncident,
          timeOfIncident: moment(timeOfIncident, "HH:mm a").format("HH:mm"),
          incidentType: incidentType.toString(),
          involvedPersons: JSON.stringify(involvedPersons),
          occurrenceNumber: occurrenceNumber,
          unitNo: unitNo,
          incidentDesc: incidentDesc,
          photos: mediaList.toString(),
          anyEmergency: isEmergency,
          emergency: JSON.stringify(emergency),
          policeName: policeName,
          policeNumber: policeNumber,
          fireName: fireName,
          fireNumber: fireNumber,
          ambulanceName: ambulanceName,
          ambulanceNumber: ambulanceNumber,
          other1Name: other1Name,
          other2Name: other2Name,
          licenseIdNo: licenseIdNo,
        }
        //console.log(payload, "payload");
        const options = {
          url,
          method: "POST",
          headers: { "Content-Type": "application/json" },
          data: payload,
        };

        const { data } = await request(options);
        //console.log(data, "data");

        if (data && data?.id) {
          if (Platform.OS === "web") {
            alert(data.message)
          }
          else {
            Alert.alert(t('Success'), t(data.message));
          }
          setImages(mediaList);
          setIncidentInfo({ ...payload, id: data?.id });
          getIncidentInfo(data?.id);
          history.goBack();
          // const newNote = {
          //   id: data?.id,
          //   submittedBy: name,
          //   description: note,
          //   picture: mediaId,
          //   inTime: moment().format("YYYY-MM-DD HH:mm a"),
          // };
          // setDailyShiftReportData([newNote, ...dailyShiftReportData]);
          // setNote("");
          // setImage("");
        } else {
          if (Platform.OS === "web") {
            alert(data.message)
          }
          else {
            Alert.alert(t('Failed'), t(data.message));
          }
        }
        setBtnLoader(false);
        setIsLoading(false);
      }
    } catch (err) {
      //console.log(err, "Add");
      setBtnLoader(false);
      setIsLoading(false);
    }
  };

  const onUpdateReport = async () => {
    try {
      const check = await validate();
      // alert(check)
      if (check) {
        setBtnLoader(true);
        setIsLoading(true);
        let mediaList = await processImages();
        //console.log(mediaList, "mediaList");
        const url = `${API()}/action/incidentReport`;
        const payload = {
          id: incidentInfo?.id,
          locationId: Number(locationId),
          firstName: firstName,
          lastName: lastName,
          dateOfIncident: dateOfIncident,
          timeOfIncident: moment(timeOfIncident, "HH:mm a").format("HH:mm"),
          incidentType: incidentType.toString(),
          involvedPersons: JSON.stringify(involvedPersons),
          occurrenceNumber: occurrenceNumber,
          unitNo: unitNo,
          incidentDesc: incidentDesc,
          photos: mediaList.toString(),
          anyEmergency: isEmergency,
          emergency: JSON.stringify(emergency),
          policeName: policeName,
          policeNumber: policeNumber,
          fireName: fireName,
          fireNumber: fireNumber,
          ambulanceName: ambulanceName,
          ambulanceNumber: ambulanceNumber,
          other1Name: other1Name,
          other2Name: other2Name,
          licenseIdNo: licenseIdNo,
        }
        const options = {
          url,
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          data: payload,
        };
        const { data } = await request(options);
        if (data && data?.id) {
          if (Platform.OS === "web") {
            alert(data.message)
          }
          else {
            Alert.alert(t('Success'), t(data.message));
          }
          setImages(mediaList);
          setIncidentInfo(payload);
          getIncidentInfo(data?.id);
          history.goBack();
          // const newNote = {
          //   id: data?.id,
          //   submittedBy: name,
          //   description: note,
          //   picture: mediaId,
          //   inTime: moment().format("YYYY-MM-DD HH:mm a"),
          // };
          // setDailyShiftReportData([newNote, ...dailyShiftReportData]);
          // setNote("");
          // setImage("");
        } else {
          if (Platform.OS === "web") {
            alert(data.message)
          }
          else {
            Alert.alert(t('Failed'), t(data.message));
          }
        }
        setBtnLoader(false);
        setIsLoading(false);
      }
    }
    catch (err) {
      //console.log(err, "Update");
      setBtnLoader(false);
      setIsLoading(false);
    }
  };

  const onChangeEmergencyType = (item) => {
    if (emergency.includes(item)) {
      let newList = [];
      let index = emergency.indexOf(item);
      emergency.map((im, ind) => {
        if (ind != index) {
          newList.push(im)
        }
      });
      //console.log(newList, "newList");
      setEmergency(newList);
    }
    else {
      let newList = [];
      emergency.map((im, ind) => {
        newList.push(im)
      });
      newList.push(item)
      setEmergency(newList);
    }
    //console.log(emergency, item);
  }

  const formateText = (text) => {
    const result = text.replace(/([A-Z])/g, " $1");
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }
  const validHHMMstring = (str) =>
    /^([01]?[0-9]|2[0-3]):[0-5][0-9] [a|p][m]$/.test(str);

  const handleShowHistory = async () => {
    try {
      if (incidentInfo) {
        setIsTraceLogsLoading(true);
        const url = `${API()}/action/fetchTraceChanges?type=incident&recordId=${incidentInfo?.id}`;

        const options = {
          url,
          method: "GET",
        };

        const { data } = await request(options);

        if (data && data?.length) {
          //console.log(data);
          const Info = { ...incidentInfo, traceLogs: data }
          setIncidentInfo(Info);
          setShowHistory(true);
        }
        setIsTraceLogsLoading(false);
      }
    } catch (error) {

      setIsTraceLogsLoading(false);
    }
  }
  return (
    <KeyboardAvoidingView behavior="padding">
      <ScrollView
        contentContainerStyle={styles.scrollInner}
        style={{ paddingBottom: 50 }}
      >
        <ButtonHeader
          location={currentLocation?.field2 || currentLocation?.id}
          title={currentButton?.field2}
        />
        <Content>
          {/* {!shiftStarted && !location.state?.incidentInfo && ( */}
          <>
            <>
              <SubTitle title="Full Name" />
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ width: "45%" }}>
                  <View style={[styles.shadow, { marginBottom: 10 }]}>
                    <View style={styles.container}>
                      <View style={styles.info}>
                        <TextInput
                          placeholder="Last Name"
                          style={[styles.title, { borderWidth: 0 }]}
                          value={lastName}
                          onChangeText={(text) => {
                            setLastName(text);
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View style={{ width: "45%" }}>
                  <View style={[styles.shadow, { marginBottom: 10 }]}>
                    <View style={styles.container}>
                      <TextInput
                        placeholder="First Name"
                        style={[styles.title, { borderWidth: 0 }]}
                        value={firstName}
                        onChangeText={(text) => {
                          setFirstName(text);
                        }}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </>

            <>
              <SubTitle title="ID/Licence Number" />
              <View style={[styles.shadow, { marginBottom: 10 }]}>
                <View style={styles.container}>
                  <TextInput
                    // editable={!shiftStarted}
                    style={[styles.title, { borderWidth: 0 }]}
                    value={licenseIdNo}
                    onChangeText={(text) => {
                      setLicenseIdNo(text);
                    }}
                  />
                </View>
              </View>
            </>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ width: "45%" }}>
                <SubTitle title="Date" />
                <View style={[styles.shadow, { marginBottom: 10 }]}>
                  <View style={styles.container} onPress={() => { }}>
                    <DatePicker
                      mode="date"
                      maximumDate
                      currentValue={Platform.OS === "web" ? dateOfIncident : dateOfIncident ? dateOfIncident : ''}
                      onChange={(value) => {
                        //console.log(value);
                        if (Platform.OS === "web") {
                          //console.log(value, "value");
                          setDateOfIncident(value);
                        } else {
                          setDateOfIncident(moment(value).format("YYYY-MM-DD"));
                          //console.log(moment(value).format("YYYY-MM-DD"));
                        }
                      }}
                    />
                  </View>
                </View>
              </View>

              <View style={{ width: "45%" }}>
                <SubTitle title="Time" />
                <View style={[styles.shadow, { marginBottom: 10 }]}>
                  <View style={styles.container} onPress={() => { }}>
                    <View style={styles.info}>
                      <DatePicker
                        mode="time"
                        currentValue={timeOfIncident}
                        onChange={(value) => {
                          if (Platform.OS === "web") {
                            setTimeOfIncident(value);
                          } else {
                            setTimeOfIncident(moment(value).format("HH:mm a"));
                          }
                        }}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>

            <>
              <SubTitle title="Incident Type" />
              <View style={[styles.shadow, { marginBottom: open ? Platform.OS == "web" ? types.length * 20 : types.length * 50 : 10 }]}>
                <View style={styles.container}>
                  <MultiPickerCustom
                    options={types}
                    selected={incidentType}
                    onChange={(value) => { setIncidentType(value) }}
                  ></MultiPickerCustom>
                </View>
              </View>
            </>
            {
              incidentType.indexOf("Other") >= 0 &&
              <>
                <View style={[styles.shadow, { marginBottom: 10 }]}>
                  <View style={styles.container}>
                    <TextInput
                      placeholder="Other Incident Type"
                      style={[styles.title, { borderWidth: 0 }]}
                      value={other2Name}
                      onChangeText={(text) => {
                        setOther2Name(text);
                      }}
                    />
                  </View>
                </View>
              </>
            }

            <>
              <SubTitle title="Involved Persons" />
              {involvedPersons?.map((involedPerson, index) => {
                return (
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ width: "45%" }}>
                      <View style={[styles.shadow, { marginBottom: 10 }]}>
                        <View style={styles.container}>
                          <View style={styles.info}>
                            <TextInput
                              placeholder="Last Name"
                              style={[styles.title, { borderWidth: 0 }]}
                              value={involedPerson?.lastName}
                              onChangeText={(text) => {
                                setInvoledPersons(
                                  involvedPersons?.map((person, ind) =>
                                    ind === index ? { ...person, lastName: text } : person
                                  )
                                );
                              }}
                            />
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={{ width: "45%" }}>
                      <View style={[styles.shadow, { marginBottom: 10 }]}>
                        <View style={styles.container}>
                          <TextInput
                            placeholder="First Name"
                            style={[styles.title, { borderWidth: 0 }]}
                            value={involedPerson?.firstName}
                            onChangeText={(text) => {
                              setInvoledPersons(
                                involvedPersons?.map((person, ind) =>
                                  ind === index ? { ...person, firstName: text } : person
                                )
                              );
                            }}
                          />
                        </View>
                      </View>
                    </View>

                    <View style={{ width: "5%", alignItems: "center", alignContent: "center" }}>
                      <Ionicons
                        style={styles.delIcon}
                        name={'trash-bin'}
                        onPress={() => {
                          let newList = [];
                          involvedPersons.map((im, ind) => {
                            if (ind != index) {
                              newList.push(im)
                            }
                          });
                          setInvoledPersons(newList);
                        }
                        }
                      />
                    </View>
                  </View>
                )
              })}
              <Text style={{ color: "blue", textAlign: "right" }} onPress={() => {
                setInvoledPersons([...involvedPersons, {
                  "firstName": "",
                  "lastName": ""
                }]);
              }}>Add New</Text>
            </>
            <>
              <SubTitle title="Unit / Suite / Apt #" />
              <View style={[styles.shadow, { marginBottom: 10 }]}>
                <View style={styles.container} onPress={() => { }}>
                  <TextInput
                    // editable={!shiftStarted}
                    style={[styles.title, { borderWidth: 0 }]}
                    value={unitNo}
                    onChangeText={(text) => {
                      setUnitNo(text);
                    }}
                  />
                </View>
              </View>
            </>
            <View style={{ marginBottom: 10 }}>
              <SubTitle title="Emergency Service involved (Fire, Police, Ambulance/Paramedic)" />
              <ToggleBool value={isEmergency} onPress={setIsEmergency} />
            </View>
            {isEmergency &&
              <View style={{ flexWrap: 'wrap', flexDirection: "row", marginVertical: 10 }}>
                {["Police", "Fire", "Ambulance", "Other"].map((item, index) => {
                  return (
                    <View onTouchStart={() => {
                      // onChangeEmergencyType(item, index);
                    }} style={[{ borderRadius: 10, margin: 5, padding: 5, paddingHorizontal: 10, backgroundColor: "#dee0e3", alignItems: "center" }, emergency.includes(item) == true ? { backgroundColor: "grey" } : { backgroundColor: "#dee0e3" }]}>
                      <Text onPress={() => {
                        onChangeEmergencyType(item, index);
                      }} style={emergency.includes(item) == true ? { color: "white" } : { color: "black" }}>{item}</Text>
                    </View>
                  )
                })}
              </View>
            }
            {isEmergency && emergency.includes("Police") &&
              <>
                <SubTitle title="Police" />
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: "48%" }}>
                    <View style={[styles.shadow, { marginBottom: 10 }]}>
                      <View style={styles.container}>
                        <View style={styles.info}>
                          <TextInput
                            placeholder="Name"
                            style={[styles.title, { borderWidth: 0 }]}
                            value={policeName}
                            onChangeText={(text) => {
                              setPoliceName(text)
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "48%" }}>
                    <View style={[styles.shadow, { marginBottom: 10 }]}>
                      <View style={styles.container}>
                        <View style={styles.info}>
                          <TextInput
                            placeholder="Badge #"
                            style={[styles.title, { borderWidth: 0 }]}
                            value={policeNumber}
                            onChangeText={(text) => {
                              setPoliceNumber(text)
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            }
            {isEmergency && emergency.includes("Fire") &&
              <>
                <SubTitle title="Fire" />
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: "48%" }}>
                    <View style={[styles.shadow, { marginBottom: 10 }]}>
                      <View style={styles.container}>
                        <View style={styles.info}>
                          <TextInput
                            placeholder="Name"
                            style={[styles.title, { borderWidth: 0 }]}
                            value={fireName}
                            onChangeText={(text) => {
                              setFireName(text)
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "48%" }}>
                    <View style={[styles.shadow, { marginBottom: 10 }]}>
                      <View style={styles.container}>
                        <View style={styles.info}>
                          <TextInput
                            placeholder="Pumper #"
                            style={[styles.title, { borderWidth: 0 }]}
                            value={fireNumber}
                            onChangeText={(text) => {
                              setFireNumber(text)
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            }
            {isEmergency && emergency.includes("Ambulance") &&
              <>
                <SubTitle title="Ambulance" />
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: "48%" }}>
                    <View style={[styles.shadow, { marginBottom: 10 }]}>
                      <View style={styles.container}>
                        <View style={styles.info}>
                          <TextInput
                            placeholder="Name"
                            style={[styles.title, { borderWidth: 0 }]}
                            value={ambulanceName}
                            onChangeText={(text) => {
                              setAmbulanceName(text)
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                  <View style={{ width: "48%" }}>
                    <View style={[styles.shadow, { marginBottom: 10 }]}>
                      <View style={styles.container}>
                        <View style={styles.info}>
                          <TextInput
                            placeholder="Ambulance #"
                            style={[styles.title, { borderWidth: 0 }]}
                            value={ambulanceNumber}
                            onChangeText={(text) => {
                              setAmbulanceNumber(text)
                            }}
                          />
                        </View>
                      </View>
                    </View>
                  </View>
                </View>
              </>
            }

            {isEmergency && emergency.includes("Other") &&
              <>
                <SubTitle title="Other" />
                <View style={[styles.shadow, { marginBottom: 10 }]}>
                  <View style={[styles.container, { flexDirection: "row" }]}>
                    <TextInput
                      placeholder="Other"
                      style={[styles.title, { borderWidth: 0 }]}
                      value={other1Name}
                      onChangeText={(text) => {
                        setOther1Name(text)
                      }}
                    />
                  </View>
                </View>
              </>
            }
            <>
              <SubTitle title="Occurrence #" />
              <View style={[styles.shadow, { marginBottom: 10 }]}>
                <View style={styles.container}>
                  <TextInput
                    // editable={!shiftStarted}
                    style={[styles.title, { borderWidth: 0 }]}
                    value={occurrenceNumber}
                    onChangeText={(text) => {
                      setOccurrenceNumber(text);
                    }}
                  />
                </View>
              </View>
            </>
            <>
              <SubTitle title="Incident Description *" />
              <View style={[styles.shadow, { marginBottom: 10 }]}>
                <View style={[styles.container, Platform.OS == "web" && { minHeight: 200 }]} onPress={() => { }}>
                  <TextInput
                    style={[styles.title, { borderWidth: 0, height: 100 }]}
                    value={incidentDesc}
                    onChangeText={(text) => {
                      setIncidentDesc(text);
                    }}
                    multiline={true}
                  />
                </View>
              </View>
            </>


            <SubTitle title="Photos" />
            <ScrollView horizontal={true}>
              <View style={{ flexDirection: "row" }}>
                {images?.map((pic, index) => {
                  return (
                    <View style={{ height: 100, width: 100, borderWidth: 1, borderRadius: 20, justifyContent: "center", alignItems: "center", marginBottom: 20, borderColor: "#1DD2C1", margin: 2 }} onPress={() => { }}>
                      <MaterialIcons
                        onPress={() => {
                          //console.log(index);
                          let newList = [];
                          images.map((im, ind) => {
                            if (ind != index) {
                              newList.push(im)
                            }
                          });
                          setImages(newList);
                        }}
                        name={'remove'}
                        style={[styles.removeImageIcon]}
                      />
                      {incidentInfo?.photos?.split(",").includes(pic) ?
                        <ImageSecured
                          progressiveRenderingEnabled
                          style={[styles.image]}
                          resizeMode="contain"
                          id={pic}
                          source={{ uri: `${API()}/action/getSecureFiles?id=${Number(pic)}` }}
                        />
                        :
                        <Image
                          progressiveRenderingEnabled
                          style={styles.image}
                          resizeMode={'contain'}
                          source={{ uri: Platform.OS === 'android' ? `file://${pic.uri}` : `${pic.uri}` }}
                        />
                      }
                    </View>
                  )
                })
                }
                <View style={{ height: 100, width: 100, borderWidth: 1, borderRadius: 20, justifyContent: "center", alignItems: "center", marginBottom: 20, borderColor: "#1DD2C1", margin: 2 }} onPress={() => { }}>
                  <Ionicons
                    onPress={() => {
                      setModal(true);
                    }}
                    style={styles.iconRight}
                    name="camera"
                  />
                </View>
              </View>
            </ScrollView>
            {/* } */}

            {incidentInfo &&
              <Control
              loading={isTraceLogsLoading}
                onPress={() => {
                  // setShowHistory(true);
                  handleShowHistory();
                }}
                title={t("Show History")}
              />
            }
            <Control
              loading={btnLoader}
              onPress={() => {
                incidentInfo ? onUpdateReport() : onAddReport();
              }}
              title={
                incidentInfo
                  ? t("Update")
                  : t("Submit")
              }
            />
          </>
          {/* )} */}

          <ModalTakePicture
            isVisible={modal}
            onHide={() => setModal(false)}
            // image={image.length && image[image.length - 1]}
            onChooseImage={(pic) => { images.push(pic); setModal(false); }}
            relaunch={() => {
              setModal(false);
              setTimeout(() => {
                setModal(true);
              }, 500);
            }}
          />


          {/* {image && (
            <ModalRead
              isVisible={imageViewModal}
              onHide={() => setImageViewModal(null)}
              imageId={image}
            />
          )} */}
          {showHistory && incidentInfo &&
            <SafeAreaView>
              <Modal
                backdropColor={EStyleSheet.value('$white')}
                backdropOpacity={0.95}
                isVisible={showHistory}
                onBackdropPress={setShowHistory}
                onBackButtonPress={setShowHistory}
                animationIn="zoomIn"
                animationOut="zoomOut"
                // onSwipeComplete={setShowHistory}
                useNativeDriver
                // swipeDirection={['down', 'left', 'up', 'right']}
                scrollHorizontal={true}
              >
                <ScrollView>
                  {
                    incidentInfo?.traceLogs?.map((history, index) => {
                      return (
                        <View style={{ paddingBottom: 20, borderBottomWidth: 1, marginTop: 20 }}>
                          <SubTitle>{history.traceType}</SubTitle>
                          <View>
                            {Object.keys(history.data).map((keyName, i) => (
                              <View>
                                {keyName != "traceLogs" && keyName != "photos" && (typeof (history.data[keyName]) === "string" || typeof (history.data[keyName]) === "number") &&
                                  <View style={{ flexDirection: "row" }}>
                                    <Text style={[styles.name, { fontWeight: "bold" }]}>{formateText(keyName)}:</Text>
                                    <Text style={styles.desc}>{history.data[keyName]}</Text>
                                  </View>
                                }
                                {/* {keyName == "emergency" &&
                                  <View style={{ flexDirection: "row" }}>
                                    <Text style={[styles.name, { fontWeight: "bold" }]}>{formateText(keyName)}:</Text>
                                    <View>
                                      {JSON.parse(history.data[keyName]).map((item, i) => {
                                        return (
                                          <>
                                            <Text style={styles.desc}>{item},</Text>
                                          </>
                                        )
                                      }
                                      )}
                                    </View>
                                  </View>
                                } */}

                                {/* {keyName == "involvedPersons" &&
                                  <View style={{ flexDirection: "row" }}>
                                    <Text style={[styles.name, { fontWeight: "bold" }]}>{formateText(keyName)}:</Text>
                                    <View>
                                      {[{names:history.data[keyName]}].map((item, i) => {
                                        return (
                                          <>
                                            {Object.keys(item).map((par, inx) => (
                                              <Text style={styles.desc}>{item[par]} </Text>
                                            ))
                                            }
                                          </>
                                        )
                                      }
                                      )}
                                    </View>
                                  </View>
                                } */}
                                {keyName == "photos" &&
                                  <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                                    <Text style={[styles.name, { fontWeight: "bold" }]}>{keyName}:</Text>
                                    {history.data[keyName].split(",")?.map((pic, index) => {
                                      return (
                                        <>
                                          {pic.length > 3 &&
                                            <View style={{ height: 50, width: 50, borderWidth: 1, borderRadius: 10, justifyContent: "center", alignItems: "center", marginBottom: 20, borderColor: "#1DD2C1", margin: 10 }}>
                                              <ImageSecured
                                                progressiveRenderingEnabled
                                                style={[styles.history_image]}
                                                resizeMode="contain"
                                                id={pic}
                                                source={{ uri: `${API()}/action/getSecureFiles?id=${Number(pic)}` }}
                                              />
                                            </View>
                                          }
                                        </>
                                      )
                                    })
                                    }
                                  </View>
                                }
                              </View>
                            ))}
                          </View>
                        </View>
                      )
                    })
                  }
                </ScrollView>
                <Control
                  onPress={() => {
                    setShowHistory(false)
                  }}
                  title={t("Go Back")}
                />
              </Modal>
            </SafeAreaView>
          }
        </Content>
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const mapStateToProps = createStructuredSelector({
  buttons: makeSelectButtons(),
  locations: makeSelectLocations(),
  userLocations: makeSelectUserLocation(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(IncidentReport);
