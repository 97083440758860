import React, { memo, useEffect, useState } from 'react';
import { BlackPortal } from 'react-native-portal';
import { ActivityIndicator, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useParams } from '../../../services/router';
import ButtonHeader from '../../../components/ButtonHeader';
import ContactPerson from '../../../components/ContactPerson';
import FloatActionButton from '../FloatActionButton';
import { t } from '../../../services/i18n';
import styles from './styles';
import { makeSelectLocations } from '../../../providers/LocationsProvider/selectors';
import decodeCMSEncoding from '../../../utils/decodeCMSEncoding';
import API from '../../../config/api';
import DatePicker from "../../../components/Form/DatePicker";
import PieChartComponent from '../../../components/Charts/PieChartComponent';
import LineChartComponent from '../../../components/Charts/LineChartComponent';
import request from '../../../utils/request';
import Title from '../../../components/Content/Title';
import Paragraph from '../../../components/Content/Paragraph';
import moment from 'moment';

function LocationAnalytics({ locations }) {

	const history = useHistory();
	const [lineChartData, setLineChartData] = useState({
		labels: [],
		datasets: [
			{
				data: []
			}
		]
	}
	);
	const [dataRes, setDataRes] = useState([]);
	const [checklistDetails, setChecklistDetails] = useState([]);
	const [startDate, setStartDate] = useState(() => {
		const today = new Date();
		const oneWeekOld = new Date(today.setDate(today.getDate() - 7));
		return oneWeekOld;
	});
	const [endDate, setEndDate] = useState(new Date());
	const [isStartPickerVisible, setStartPickerVisible] = useState(false);
	const [isEndPickerVisible, setEndPickerVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const { locationId } = useParams();

	const currentLocation = locations.find((el) => el.id === locationId);


	// Usage
	useEffect(() => {
		fetchData('barchart-count', locationId, startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0], 'bar');
		fetchData('checklist-count', `,${locationId},`, startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0], 'pie');
		console.log(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0], "======")
	}, [locationId, startDate, endDate]);

	const fetchData = async (dataType, locationId, startDate, endDate, chartType) => {
		setLoading(true);
		const url = `${API()}/action/fetchChartDetails?dataType=${dataType}`;
		const payload = {
			locations: locationId,
			startDate,
			endDate,
			checklists: "all"
		};

		const options = {
			url,
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: new URLSearchParams(payload).toString()
		};

		console.log(payload, "payloadpayloadpayloadsss")
		try {
			const { data } = await request(options);

			if (chartType === 'bar') {
				const labels = Object.keys(data[0]);
				const datasets = Object.values(data[0]);
				const chartData = {
					labels: labels,
					datasets: [
						{
							data: datasets
						}
					]
				};
				setLineChartData(chartData);
				setLoading(false);
			} else if (chartType === 'pie') {
				const colors = ["rgba(131, 167, 234, 1)", "#F00", "rgb(0, 0, 255)", "#b2b2b2"];
				const newRes = data.map((item, index) => ({
					name: item.checklist_status,
					population: item.sum,
					color: colors[index],
					legendFontColor: "#7F7F7F",
					legendFontSize: 15
				}));
				setDataRes(newRes);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};

	const fetchDetail = async (dataType) => {
		const url = `${API()}/action/fetchChartDetails?dataType=${dataType}`;

		const payload = {
			locations: '8637719,8735363,8639206',//locationId,
			startDate: moment(startDate).format("YYYY-MM-DD"),
			endDate: moment(endDate).format("YYYY-MM-DD"),
			checklists: "all",
			collect: 'not_completed'
		};

		const options = {
			url,
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: new URLSearchParams(payload).toString()
		};

		try {
			const { data } = await request(options);
			console.log(data?.length, "payloadpayloadpayloadsss")
			if (data && data.length) {
				setChecklistDetails(data)
			}

		} catch (error) {
			setLoading(false);
			console.error('Error fetching data:', error);
		}
	};


	return (
		<ScrollView contentContainerStyle={styles.scrollInner}>
			<View style={styles.container}>
				<Paragraph content={currentLocation?.field2 || currentLocation?.id} />
				<Title title={"Analytics"} />

				{/* <ButtonHeader
					title={t('Analytics')}
					context={currentLocation?.field2 || currentLocation?.id}
					icon="md-pin"
				/> */}
				<View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
					<View style={{ flexDirection: 'row', alignItems: 'center', marginRight: 10 }}>
						<View style={{
							borderLeftWidth: 8,
							borderLeftColor: 'green',
							borderWidth: 1, borderColor: '#0b6ae7', borderRadius: 10,
						}}>
							<TouchableOpacity style={{
								width: 170,
								paddingVertical: 5,
								borderColor: '#0b6ae7',
							}}
								onPress={() => setStartPickerVisible(true)} >
								<Text> Start Date: {startDate.toISOString().split('T')[0]}</Text>
							</TouchableOpacity>
						</View>
						<DatePicker
							modal
							open={isStartPickerVisible}
							date={startDate}
							onConfirm={(date) => {
								setStartPickerVisible(false);
								setStartDate(date);
							}}
							onCancel={() => {
								setStartPickerVisible(false);
							}}
						/>
					</View>
					<Text style={{ marginRight: 4 }}>to </Text>
					<View style={{ flexDirection: 'row', alignItems: 'center' }}>
						<View style={{
							borderLeftWidth: 8,
							borderLeftColor: 'green',
							borderWidth: 1, borderColor: '#0b6ae7', borderRadius: 10,
						}}>
							<TouchableOpacity onPress={() => setEndPickerVisible(true)}
								style={{
									borderRadius: 10,
									width: 160,
									paddingVertical: 5,
									backgroundColor: '#fff',
									paddingHorizontal: '.5rem',
									paddingVertical: 5,
									// borderLeftWidth: 8,
									// borderRightColor: '#0b6ae7',
									bordertWidth: 2,
									borderColor: '#0b6ae7',
								}}
							>
								<Text> End Date: {endDate.toISOString().split('T')[0]}</Text>
							</TouchableOpacity>
						</View>
						<DatePicker
							modal
							open={isEndPickerVisible}
							date={endDate}
							onConfirm={(date) => {
								setEndPickerVisible(false);
								setEndDate(date);
							}}
							onCancel={() => {
								setEndPickerVisible(false);
							}}
						/>
					</View>
				</View>

				<View style={{ justifyContent: "center", marginTop: 10 }}>
					{loading &&
						<ActivityIndicator size={"large"} color={"#D3D3D3"} />
					}


					{dataRes?.length > 0 &&
						<View style={{ marginBottom: 20, alignItems: "center" }}>
							<PieChartComponent locationId={locationId}
								data={dataRes}
							/>

							<TouchableOpacity onPress={() => { fetchDetail("checklist") }}><Text style={[styles.bold, { color: "blue", marginBottom: 10 }]}>Show Details</Text></TouchableOpacity>
							<View style={{ width: "100%" }}>
								{checklistDetails && checklistDetails.map((checklist, index) => {
									return (
										<View key={index} style={styles.shadow}>
											<TouchableOpacity
												style={styles.tile}
												// onPress={() => { history.push({pathname: `/location/${locationId}/button/${checklist?.checklist_id}/checklist`}) }}
											>
												<Text style={styles.title}>
													{checklist.checklist}
												</Text>
												<Text style={styles.bold}>
													Completed: {checklist?.completed}
												</Text>
												<Text style={styles.bold}>
													Not completed: {checklist.not_completed}
												</Text>
											</TouchableOpacity>
										</View>
									)
								})}
							</View>
						</View>
					}
					{lineChartData?.labels?.length > 0 && <LineChartComponent locationId={locationId} data={lineChartData} />}
				</View>
			</View>
		</ScrollView>
	);
}

const mapStateToProps = createStructuredSelector({
	locations: makeSelectLocations(),
});

const withConnect = connect(
	mapStateToProps,
);

export default compose(
	withConnect,
	memo,
)(LocationAnalytics);
