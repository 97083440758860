import EStyleSheet from "react-native-extended-stylesheet";

import AES from "crypto-js/aes";
import Base64 from "crypto-js/enc-base64";
import ecb from "crypto-js/mode-ecb";
import Pkcs7 from "crypto-js/pad-pkcs7";
import utf8 from "crypto-js/enc-utf8";
import { encode as btoa } from "base-64";
import { Buffer } from "redux-saga";

export function applyMagic(value) {
  const keyBase64 = "xxLMr2ScLLpHKfbEQzfLB3VErYPG5Xvc";
  var key = Base64.parse(keyBase64);
  let encrypted = btoa(
    AES.encrypt(utf8.parse(value), key, { padding: Pkcs7, mode: ecb })
  ).toString("utf-8");
  return encrypted;
}

export default EStyleSheet.create({
  background: {
    flex: 1,
    backgroundColor: "$blueDark",
  },
  box: {
    width: "100%",
    padding: "2rem 3rem",
    backgroundColor: "$white",
    borderTopRightRadius: "$borderRadius",
    borderTopLeftRadius: "$borderRadius",
    flex: 1,
    minHeight: "100% - 10rem",
  },
  version: {
    fontFamily: "$fontRegular",
    fontSize: ".6rem",
    color: "$text",
    marginBottom: "1rem",
  },
  scrollInner: {
    flexGrow: 1,
  },

	modalContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	modalContent: {
		width: '80%',
		padding: 20,
		backgroundColor: 'white',
		borderRadius: 10,
		alignItems: 'center',
		elevation: 5,
	},
	modalText: {
		fontSize: 18,
		marginBottom: 20,
	},
});
