// Checklist related
export const CHECKLIST_LOADED_FAIL = 'app/ButtonsProvider/CHECKLIST_LOADED_FAIL';
export const CHECKLIST_LOADED_SUCCESS = 'app/ButtonsProvider/CHECKLIST_LOADED_SUCCESS';
export const LOAD_CHECKLIST = 'app/ButtonsProvider/LOAD_CHECKLIST';
export const LOAD_CHECKLIST_RESPONSES = 'app/ButtonsProvider/LOAD_CHECKLIST_RESPONSES';
export const DELETE_CURRENT_IN_PROGRESS = 'app/ButtonsProvider/DELETE_CURRENT_IN_PROGRESS';
export const RESET_LOADING = 'app/ButtonsProvider/RESET_LOADING';
export const RESET_ERROR = 'app/ButtonsProvider/RESET_ERROR';
// Question related
export const SET_QUESTION_RESPONSE = 'app/ButtonsProvider/SET_QUESTION_RESPONSE';
export const SET_QUESTION_NFC = 'app/ButtonsProvider/SET_QUESTION_NFC';
export const SET_QUESTION_DEFICIENCY = 'app/ButtonsProvider/SET_QUESTION_DEFICIENCY';
export const SET_QUESTION_NFC_SCANNING = 'app/ButtonsProvider/SET_QUESTION_NFC_SCANNING';
export const SET_QUESTION_PIC = 'app/ButtonsProvider/SET_QUESTION_PIC';
export const SET_QUESTION_NFC_VALIDATION = 'app/ButtonsProvider/SET_QUESTION_NFC_VALIDATION';
export const DELETE_QUESTION_NFC = 'app/ButtonsProvider/DELETE_QUESTION_NFC';
export const ADD_QUESTION_NFC = 'app/ButtonsProvider/ADD_QUESTION_NFC';
export const ADD_QUESTION_NFC_SUCCESS = 'app/ButtonsProvider/ADD_QUESTION_NFC_SUCCESS';
export const EDIT_QUESTION_NFC = 'app/ButtonsProvider/EDIT_QUESTION_NFC';
// Send Response
export const SEND_RESPONSE = 'app/ButtonsProvider/SEND_RESPONSE';
export const SEND_QUE_RESPONSE = 'app/ButtonsProvider/SEND_QUE_RESPONSE';
export const SEND_RESPONSE_SUCCESS = 'app/ButtonsProvider/SEND_RESPONSE_SUCCESS';
export const SEND_QUE_RESPONSE_SUCCESS = 'app/ButtonsProvider/SEND_QUE_RESPONSE_SUCCESS';
export const SEND_RESPONSE_FAIL = 'app/ButtonsProvider/SEND_RESPONSE_FAIL';
export const SEND_IN_QUE = 'app/ButtonsProvider/SEND_IN_QUE';
// Load response
export const LOAD_RESPONSES_SUCCESS = 'app/ButtonsProvider/LOAD_RESPONSES_SUCCESS';
export const LOAD_RESPONSES_FAIL = 'app/ButtonsProvider/LOAD_RESPONSES_FAIL';
export const SEND_RESPONSE_RESET_LOADER = 'app/ButtonsProvider/SEND_RESPONSE_RESET_LOADER';
export const LOAD_RESPONSES_BY_IDS = 'app/ButtonsProvider/LOAD_RESPONSES_BY_IDS';
export const QUE_RESET = 'app/ButtonsProvider/QUE_RESET';
