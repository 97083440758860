import EStyleSheet from 'react-native-extended-stylesheet';

const styles = EStyleSheet.create({
	container: {
		paddingVertical: '2rem',
		paddingHorizontal: '1rem',
	},
	scrollInner: {
		flexGrow: 1,
	},
	shadow: {
		borderRadius: '$borderRadius',
		shadowColor: '#000',
		shadowOffset: {
			width: 0,
			height: 2,
		},
		shadowOpacity: 0.25,
		shadowRadius: 3.84,
		elevation: 3,
		marginVertical:3,
		width:"100%"
	},
	tile: {
		borderRadius: '$borderRadius',
		backgroundColor: '$white',
		paddingHorizontal: '1rem',
		paddingVertical: '.75rem',
		borderLeftWidth: 8,
		borderLeftColor: '$green',
	},
	info: {
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	title: {
		fontSize: '.8rem',
		color: '$text',
		textAlign: 'left',
		flex: 1,
	},
	bold: {
		fontSize: '.7rem',
		color: '$text',
		fontFamily: '$fontBold',
		textAlign: 'left',
	},
});

export default styles;
