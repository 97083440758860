import React, { useState, useEffect } from 'react';
import {
	View, Text, Platform, Image,
} from 'react-native';

import Alert from '../../services/alert';
import Paragraph from '../Content/Paragraph';
import SubTitle from '../Content/SubTitle';
import Control from '../Control';
import { t } from '../../services/i18n';
import styles from './styles';
import Switch from '../Form/Switch';
import ToggleBool from '../ToggleBool';
import TextArea from '../Form/TextArea';
import ModalTakePicture from './ModalTakePicture';
import ModalNfcInput from './ModalNfcInput';
import PickerCustom from '../Form/Picker';
import ImageSecured from '../../containers/ImageSecured';
import API from '../../config/api';
import { string } from 'prop-types';

function ChecklistCardEdit({
	nfc,
	setNfc,
	nfcTapped,
	deficiency,
	setDeficiency,
	scanningNFC,
	setScanningNFC,
	deficiencyMessage = '',
	setDeficiencyMessage,
	nfcReason = '',
	setNfcReason,
	toggly,
	setToggly,
	image,
	setImage,
	removeImage,
	text,
	setText,
	question,
	current,
}) {
	const [modal, setModal] = useState(false);
	const [modalNfc, setModalNfc] = useState(false);
	const [options, setOptions] = useState([]);
	const [txtValue, setTxtValue] = useState(text);
	const [nfcTxtValue, setNfcTxtValue] = useState(nfcReason);
	const [nfcActive, setNfcActive] = useState(true);

	function tapNfc() {
		if (Platform.OS === 'web') {
			if (nfc) {
				setModalNfc(false);
			} else {
				setModalNfc(true);
			}
		} else if (Platform.OS === 'ios' & nfcActive) {
			setNfcActive(false);
			nfcTapped();
			setTimeout(() => {
				setNfcActive(true);
			}, 2000);
		} else if (nfc) {
			Alert.alert(t('explainNfcDoneTitle'), t('explainNfcDoneMessage'));
		} else {
			Alert.alert(t('explainNfcTitle'), t('explainNfcMessage'));
		}
	}

	useEffect(() => {
		if (Platform.OS === 'web' && nfc) {
			setModalNfc(false);
		}
	}, [nfc]);
	useEffect(() => {
		let optionList = []
		if (question.responseOptions) {
			JSON.parse(question.responseOptions).map((option) => {
				optionList.push({ value: option, label: t(option) })
			})
		}
		if (optionList.length > 0 && !text && text === '') {
			setText(optionList[0].value);
		}
		setOptions(optionList);
	}, []);

	return (
		<>
			<View style={[styles.card, current && styles.cardCurrent]}>
				<View style={styles.cardHeader}>
					<Text style={styles.title}>{`Question ${question.i + 1}`}</Text>
					{(question.responseType === 'confirm' || question.responseType === 'yes-no') && (
						<ToggleBool
							radio={question.responseType === 'confirm'}
							value={toggly}
							onPress={setToggly}
						/>
					)}
				</View>
				<Text style={styles.subTitle}>{question.name}</Text>
				<Paragraph>{question.description}</Paragraph>

				{Platform.OS !== 'web' ?
					<View style={{ flexDirection: "row", marginBottom: 30 }}>
						{
							question?.qPictures && JSON.parse(question?.qPictures)?.map((image) => {
								return (
									<View>
										<ImageSecured
											title={image.title}
											isMax={true}
											progressiveRenderingEnabled
											style={[styles.image]}
											resizeMode="contain"
											id={image.id.toString()?.split("&")[0]}
											source={{ uri: `${API()}/action/getSecureFiles?id=${image.id.toString()?.split("&")[0]}` }}
										/>
										<Text style={{ fontSize: 8, textAlign: "center", marginVertical: 2 }}>{image.title}</Text>
									</View>
								)
							})
						}
					</View>
					:
					<View style={{ flexDirection: "row", marginBottom: 30 }}>
						{
							question?.qPictures && typeof (question?.qPictures) != 'string' && question?.qPictures.length > 0 && question?.qPictures?.map((image, index) => {
								return (
									<View key={index}>
										<Image style={[styles.image]} resizeMode="contain" source={{ uri: Platform.OS === 'android' ? `file://${image.base64}` : image.base64 }}></Image>
										<Text style={{ fontSize: 8, textAlign: "center", marginVertical: 2 }}>{image.title}</Text>
									</View>
								)
							})
						}
					</View>
				}
				{question.responseType === 'text' && <TextArea value={text} onChangeText={setText} noShadow />}
				{question.responseType === 'dropdown' &&
					<PickerCustom
						options={options}
						selected={text}
						onChange={(value) => { setText(value) }}
					/>}
				{question.pictureOption && (
					<Control
						onPress={() => setModal(true)}
						small
						theme={image ? 'success' : 'altLight'}
						title={image ? t('View picture') : t('Take a Picture')}
					/>
				)}
				{question.NFCValidation && (
					<>
						{/* <Switch
							value={scanningNFC}
							onValueChange={() => setScanningNFC(scanningNFC ? !scanningNFC : true)}
							label={t('Not Scanning NFC')}
						/> */}
						<Control
							small
							theme={nfc ? 'success' : 'altLight'}
							onPress={tapNfc}
							title={`NFC Tag (${question.NFCTitle})`}
							loading={!nfcActive}
							disabled={!nfcActive}
						/>
						{!nfc && <>
							<Paragraph>Reason NFC not scanned</Paragraph>
							<TextArea numberOfLines={2} value={nfcTxtValue} onChangeText={setNfcTxtValue} onBlue={()=>{setNfcReason(nfcTxtValue);}} noShadow style={{ height: 100 }} />
						</>}
					</>
				)}
				<Switch
					value={deficiency}
					onValueChange={() => setDeficiency(deficiency ? !deficiency : true)}
					label={t('Urgent notification')}
				/>
				{deficiency && (
					<>
						<SubTitle title={t('Explain')} />
						<TextArea value={deficiencyMessage} onChangeText={setDeficiencyMessage} noShadow />
					</>
				)}
			</View>
			{question.pictureOption && (
				<ModalTakePicture
					isVisible={modal}
					onHide={() => setModal(false)}
					image={image}
					onChooseImage={(pic) => setImage(pic)}
					removeImage={removeImage}
					relaunch={() => {
						setModal(false); setTimeout(() => {
							setModal(true);
						}, 500);
					}}
				/>
			)}
			{Platform.OS === 'web' && question.NFCValidation && (
				<ModalNfcInput
					isVisible={modalNfc}
					onHide={() => setModalNfc(false)}
					question={question}
					onChooseNfc={setNfc}
				/>
			)}
		</>
	);
}

export default ChecklistCardEdit;
