import React, { useEffect } from 'react';
import {
	Dimensions,
	Text, View,TouchableOpacity
} from 'react-native';
import { useHistory, useLocation } from '../../services/router';
import {
	BarChart,
	LineChart
} from "react-native-chart-kit";
import API from '../../config/api';
import request from '../../utils/request';

function LineChartComponent({locationId,data}) {
	const history = useHistory();
	const location = useLocation();
	
	

	
	return (
		<View style={{ marginBottom: 10, marginRight: 10 }}>
				
			<BarChart
				// style={graphStyle}
				
				data={data}
				width={Dimensions.get("window").width-35} // from react-native
				height={220}
				yAxisLabel=""
				chartConfig={{
					backgroundColor: "#479DFF",
					backgroundGradientFrom: "#f7f7f7",
					backgroundGradientTo: "#f7f7f7",
					decimalPlaces: 2, // optional, defaults to 2dp
					color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
					labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
					style: {
						borderRadius: 16,
					},
					// propsForDots: {
					// 	r: "6",
					// 	strokeWidth: "2",
					// 	stroke: "#ffa726"
					// }
				}}
				bezier
				style={{
					marginVertical: 8,
					borderRadius: 16,
				}}
			/>
		</View>
	);
}

export default LineChartComponent;
